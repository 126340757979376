
import JnJuranewsSearch from '@/views/search/JuranewsSearch.vue';
import JnCardsStrip from '@/views/common/CardsStrip.vue';
import JnSearchResults from '@/views/search/SearchResults.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { SearchData } from '@/models/search.model';
import { Categories } from '@/models/enums.model';
import { Testata } from '@/models/testata.model';
import MetaInfo from 'vue-meta';

@Component({
  components: {
    JnJuranewsSearch,
    JnCardsStrip,
    JnSearchResults
  },
  metaInfo(this: JnNews): MetaInfo {
    return { title: 'JuraNews - News' };
  },
})
export default class JnNews extends Vue {
  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    searchScope: 'news',
  });

  inEvidenzaSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    size: 100,
    withText: true,
    perPage: 8,
    idCategory: [Categories.ULTIME_NEWS],
  });

  approfondimentiSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    perPage: 2,
    size: 100,
    withText: true,
    idCategory: [Categories.APPROFONDIMENTI],
  });

  created() {
    this.testata = JnService.getTestata(Categories.ULTIME_NEWS);
  }
}
